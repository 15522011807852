import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"
import Image from "../utils/Image"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import SwipeableViews from "../molecules/SwipeableViews"
import Card from "../atoms/Card"

import SectionTitle from "../molecules/SectionTitle"

const ImageCarousel = ({
  section,
  sectionTitle,
  images,
  borderRadius,
  showArrows,
  showIndicators,
}) => {
  return (
    <Section {...section}>
      <Reveal>
        <>
          {sectionTitle && (
            <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
          )}
          <SwipeableViews indicators={showIndicators} arrows={showArrows}>
            {images.map((image, i) => (
              <Card
                ratio={0.55}
                shadow="none"
                key={i}
                borderRadius={borderRadius}
              >
                <Image
                  alternativeText="Image In A Carousel"
                  {...image}
                  style={{ height: "100%" }}
                  imgStyle={{ objectFit: "cover" }}
                />
              </Card>
            ))}
          </SwipeableViews>
        </>
      </Reveal>
    </Section>
  )
}

ImageCarousel.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  images: PropTypes.arrayOf(PropTypes.object),
}

ImageCarousel.propTypes = {
  ...ImageCarousel.strapiProps,
}

export default ImageCarousel

export const query = graphql`
  fragment ImageCarousel on STRAPI__COMPONENT_SECTIONS_IMAGE_CAROUSEL {
    strapi_id
    IMAGECAROUSELSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    images {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1500
            quality: 100
            layout: CONSTRAINED
          )
        }
      }
    }
    borderRadius
    showArrows
    showIndicators
  }
`
