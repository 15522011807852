import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"

import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import Logo from "./Logo"
import Image from "../utils/Image"

const animateTrack = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`

const CarouselTrack = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 200vw; /* need explicit width here */
  min-width: fit-content; /* gives logos adequate spacing on smaller screens */
  justify-content: space-between;

  transform-origin: left;
  &:first-of-type {
    animation: ${animateTrack} ${props => props.numItems * props.speed}s linear
      infinite;
  }
  &:nth-of-type(2) {
    animation: ${animateTrack} ${props => props.numItems * props.speed}s linear
      infinite;
  }
  .scrollBox {
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
    height: auto;
  }
  ${props => (props.customImageWidth ? `align-items: center;` : null)}
`

const MIN_ARRAY_LENGTH = 15

const CarouselTrackInstance = ({
  carouselItems,
  cardFront,
  cardBack,
  align,
  verticalAlign,
  customImageWidth,
  customImageHeight,
  customSizeMobile,
  logoOrImage,
  carouselSpeed,
  hoverBehavior,
  zoomOnHover,
}) => {
  const speed = Math.round((carouselSpeed * 0.01 + Number.EPSILON) * 100) / 100
  const calculatedTiming = Math.abs(-(-8) * speed + (-1 * speed + -8))
  const isLogo = logoOrImage === "logo"
  const isImage = logoOrImage === "image"

  return (
    <CarouselTrack
      numItems={carouselItems.length || 10}
      customImageWidth={customImageWidth}
      customImageHeight={customImageHeight}
      speed={calculatedTiming}
    >
      {carouselItems.map((item, i) => {
        const hasOverlayImage = item?.overlayImage?.id ? true : false
        const image = item?.image
        let imgRatio
        if (image) {
          imgRatio =
            Math.round((image.width / image.height + Number.EPSILON) * 100) /
            100
        }
        return (
          <>
            {isImage && item?.image && (
              <Box
                key={i}
                className="scrollBox"
                display="flex"
                mx={{ _: 3, sm: 5 }}
                width={
                  customImageWidth
                    ? {
                        _: customSizeMobile ? `${customSizeMobile}px` : 8,
                        md: `${customImageWidth}px`,
                      }
                    : `${customImageHeight * imgRatio}px`
                }
                height={
                  customImageHeight
                    ? {
                        _: customSizeMobile ? `${customSizeMobile}px` : 8,
                        md: `${customImageHeight}px`,
                      }
                    : `100%`
                }
              >
                <Image
                  alternativeText="Image in Scrolling Carousel"
                  {...item.image}
                  loading="eager"
                />
              </Box>
            )}
            {isLogo && (
              <Box
                position="relative"
                key={i}
                width={
                  customImageWidth
                    ? {
                        _: customSizeMobile ? `${customSizeMobile}px` : 8,
                        sm: `${customImageWidth}px`,
                      }
                    : `${customImageHeight * imgRatio}px`
                }
                height={
                  customImageHeight
                    ? {
                        _: customSizeMobile ? `${customSizeMobile}px` : 8,
                        sm: `${customImageHeight}px`,
                      }
                    : `100%`
                }
                mx={{ _: 3, sm: 5 }}
              >
                <Logo
                  logoBoxFrontCardPadding={{ _: 4, md: 5 }}
                  shouldShowInfo={true}
                  cardFront={cardFront}
                  cardBack={cardBack}
                  height="100%"
                  hoverBehavior={hoverBehavior}
                  zoomOnHover={zoomOnHover}
                  align={align}
                  verticalAlign={verticalAlign}
                  {...item}
                />
              </Box>
            )}
          </>
        )
      })}
    </CarouselTrack>
  )
}

const ScrollCarousel = ({
  carouselItems,
  cardFront,
  cardBack,
  align,
  verticalAlign,
  logoOrImage,
  customImageWidth,
  customImageHeight,
  customSizeMobile,
  carouselSpeed,
  hoverBehavior,
  zoomOnHover,
}) => {
  const [lengthenedCarouselItems, setLengthenedCarouselItems] = useState([])

  useEffect(
    function lengthenLogoItemsArray() {
      let buildingArray = carouselItems
      while (buildingArray.length < MIN_ARRAY_LENGTH) {
        buildingArray = buildingArray.concat(carouselItems)
      }
      setLengthenedCarouselItems(buildingArray)
    },
    [carouselItems]
  )

  // Height needs to match height of Box around Logo Items
  return (
    <Box height={"100%"}>
      <Flex
        py="50px"
        my="-50px"
        position="relative"
        left="0"
        width="100%"
        overflow="hidden"
        alignItems="center"
      >
        <CarouselTrackInstance
          cardFront={cardFront}
          cardBack={cardBack}
          align={align}
          verticalAlign={verticalAlign}
          carouselItems={lengthenedCarouselItems}
          customImageWidth={customImageWidth}
          customImageHeight={customImageHeight}
          customSizeMobile={customSizeMobile}
          logoOrImage={logoOrImage}
          carouselSpeed={carouselSpeed}
          hoverBehavior={hoverBehavior}
          zoomOnHover={zoomOnHover}
        />
        <CarouselTrackInstance
          cardFront={cardFront}
          cardBack={cardBack}
          align={align}
          verticalAlign={verticalAlign}
          carouselItems={lengthenedCarouselItems}
          customImageWidth={customImageWidth}
          customImageHeight={customImageHeight}
          customSizeMobile={customSizeMobile}
          logoOrImage={logoOrImage}
          carouselSpeed={carouselSpeed}
          hoverBehavior={hoverBehavior}
          zoomOnHover={zoomOnHover}
        />
      </Flex>
    </Box>
  )
}

export default ScrollCarousel
