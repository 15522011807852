import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"

import FilteredGrid from "../molecules/FilteredGrid"
import TeamCard from "../molecules/TeamCard"
import SectionTitle from "../molecules/SectionTitle"

const TeamGrid = ({
  section,
  sectionTitle,
  TGSectionTitle,
  flexGrid,
  TGFlexGrid,
  card,
  TGCard,
  teamCards,
  imageShape,
  showNameOnHover,
  zoomImageOnHover,
  align,
  filter,
  TGFilter,
  filters,
  TGFilters,
  teamGridImageRatio,
  imageRatio,
  teamGridImageWidth,
  imageWidth,
  sort,
  TGSort,
  filterSortLocation,
}) => {
  sectionTitle = TGSectionTitle || sectionTitle
  flexGrid = TGFlexGrid || flexGrid
  card = TGCard || card
  filters = TGFilters || filters
  filter = TGFilter || filter
  sort = TGSort || sort

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filters={filters}
        filtersStyles={{
          filterLocation: filter?.filterLocation,
          alignMenuItems: filter?.alignMenuItems,
          showFilterLabel: filter?.showFilterLabel,
          layout: filter?.layout,
          dropdownCard: filter?.dropdownCard,
        }} // only sending style fields to avoid possibly overwriting filter data
        card={{
          theme: section.theme,
          bg: section.bg,
          borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
        sort={{
          ...sort,
          optionsObj: {
            customOrderBool: sort?.customOrderTitle || "Custom Order",
            optionOneBool: "First Name",
            optionTwoBool: "Last Name",
            optionThreeBool: "Position Title",
          },
        }}
        filterSortLocation={filterSortLocation}
      >
        {teamCards &&
          teamCards.map((person, i) => {
            return (
              <TeamCard
                key={i}
                {...person}
                card={card}
                align={align}
                imageShape={imageShape}
                showNameOnHover={showNameOnHover}
                zoomImageOnHover={zoomImageOnHover}
                imageRatio={teamGridImageRatio || imageRatio}
                imageWidth={teamGridImageWidth || imageWidth}
              />
            )
          })}
      </FilteredGrid>
    </Section>
  )
}

export default TeamGrid

TeamGrid.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  imageShape: PropTypes.oneOf(["default", "square", "circle"]),
  showNameOnHover: PropTypes.bool,
  zoomImageOnHover: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  teamCards: PropTypes.arrayOf(PropTypes.shape(TeamCard.strapiProps)),
}

TeamGrid.propTypes = {
  ...TeamGrid.strapiProps,
}

export const query = graphql`
  fragment TeamGrid on STRAPI__COMPONENT_SECTIONS_TEAM_GRID {
    strapi_id
    TEAMGRIDSection: section {
      ...Section
    }
    TGSectionTitle: sectionTitle {
      ...SectionTitle
    }
    TGFlexGrid: flexGrid {
      ...FlexGrid
    }
    TGCard: card {
      ...Card
    }
    teamCards {
      ...TeamCard
    }
    TGFilters: filters {
      ...Filter
    }
    TGFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    imageShape
    showNameOnHover
    zoomImageOnHover
    align
    teamGridImageRatio: imageRatio
    teamGridImageWidth: imageWidth
    TGSort: sort {
      ...Sort
    }
    filterSortLocation
  }
`
