import React, { useRef, useEffect } from "react"

import styled from "styled-components"
import css from "../../lib/styled-system/css"

import Popper from "@material-ui/core/Popper"
import Grow from "@material-ui/core/Grow"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import { Text } from "../atoms/Typography"

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  & :hover {
    ${props =>
      css({
        ...props.hover,
      })}
  }
`

const StyledMenuList = styled(MenuList)`
  & span {
    ${css({ color: "text" })}
  }
`

const PopUpMenu = ({
  show: open,
  options,
  anchorEl,
  onSelect,
  location,
  active,
  handleClose,
  card,
  alignMenuItems = "center",
}) => {
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      handleClose()
    }
  }

  const handlePopupItemStyle = () => {
    return {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "270px",
      whiteSpace: "nowrap",
      display: "inline-block",
      width: "100%",
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorEl.focus()
    }

    prevOpen.current = open
  }, [open, anchorEl])

  const handlePopupPlacement = alignmentStr => {
    switch (alignmentStr) {
      case "center":
        return "bottom"
      case "left":
        return "bottom-start"
      case "right":
        return "bottom-end"
      default:
        return "bottom-start"
    }
  }
  return (
    <Popper
      style={{ zIndex: 100 }}
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement={handlePopupPlacement(location)}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" || "bottom-start"
                ? "center top"
                : "center bottom",
            marginTop: "1px",
          }}
        >
          <Card mt={-6} minWidth={14} {...card}>
            <ClickAwayListener onClickAway={handleClose}>
              <StyledMenuList
                autoFocusItem={open}
                onKeyDown={handleListKeyDown}
              >
                {options?.map((option, idx) => (
                  <Box key={idx} my={2}>
                    <StyledMenuItem
                      disableRipple={true}
                      onClick={() => {
                        onSelect(option)
                        handleClose()
                      }}
                      hover={{ bg: `background.${(card.bg + 1) % 5}` }}
                    >
                      <Text
                        fontWeight={active === option ? "bold" : null}
                        textAlign={
                          alignMenuItems === "left"
                            ? "left"
                            : alignMenuItems === "right"
                            ? "right"
                            : "center"
                        }
                        px={3}
                        fontSize={1}
                        {...handlePopupItemStyle()}
                      >
                        {option}
                      </Text>
                    </StyledMenuItem>
                  </Box>
                ))}
              </StyledMenuList>
            </ClickAwayListener>
          </Card>
        </Grow>
      )}
    </Popper>
  )
}

export default PopUpMenu
