import React from "react"

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import Box from "../atoms/Box"

import Image from "../utils/Image"

const CascadeGrid = ({ children, flexGrid, maxWidth, cascadeGutter }) => {
  // const columnCountObj = constructColumnCountObj(maxWidth, flexGrid.columns)
  return (
    <>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1024: 3 }}>
        <Masonry gutter={cascadeGutter}>
          {children.map((child, i) => {
            return (
              <Box key={i} style={{ borderRadius: "5px" }}>
                <Image
                  alternativeText="Image Tile in Cascade Grid Layout"
                  {...child.image}
                />
              </Box>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
    </>
  )
}

// TODO::: use maxWidth and columns to calculate columnsCountBreakPoints object
// FUNCTION IS NOT IMPLEMENTED OR COMPLETE
const constructColumnCountObj = (maxWidth, columns) => {
  const gatsbyBreakPoints = [425, 640, 768, 1024, 1280]
  let breakPoints = {}

  // get max columns
  let cols = []
  Object.keys(columns).map(bp => {
    cols.push(columns[bp])
  })

  const maxCol = Math.max(...cols)
  for (let i = 1; i <= maxCol; i++) {
    if (maxWidth > gatsbyBreakPoints[i - 1]) {
      // all below gatsby breakpoint
      breakPoints[gatsbyBreakPoints[i - 1]] = i
    } else if (maxWidth <= gatsbyBreakPoints[i - 1] && i === maxCol) {
      //
      breakPoints[parseInt(maxWidth)] = i
    }
  }

  return breakPoints
}

export default CascadeGrid
