import React from "react"
import styled from "styled-components"
// import { graphql } from "gatsby"

import Reveal from "../../utils/Reveal"

import Box from "../../atoms/Box"
import ImageOrVideo from "../../atoms/ImageOrVideo"
import LayoutChanger from "../../atoms/LayoutChanger"
import StandardPadding from "../../atoms/StandardPadding"
import { breakpoints } from "../../../lib/styled-system/constants"

// function to generate the media query for a given breakpoint index
const set16By9AspectRatioForBreakpoint = breakPoint => `
  @media (max-width: ${breakPoint}) {
    [class^="Image__GatsbyImageWrapper"] {
      padding-top: 56.25% !important;
    }
  }
`

const StyledImageOrVideo = styled(ImageOrVideo)`
  .gatsby-image-wrapper,
  [class^="Image__GatsbyImageWrapper"] {
    & div {
      ${props =>
        props.mediaPositioningNotFloating ? `padding-bottom: 31%;` : null};
    }
  }

  ${props =>
    props.isMobileImage16By9AspectRatio
      ? set16By9AspectRatioForBreakpoint(breakpoints[0])
      : null}// Apply 16:9 aspect ratio for images in mobile breakpoint (425px)
`

const ImageTextMolecule = ({
  align,
  card = {},
  child1Px,
  imageOrVideo,
  layoutStyle,
  location,
  mediaLocation,
  mediaPositioning,
  mediaPositioningOnMobile,
  mediaRatio,
  mediaWidth,
  section,
  text,
  textChild,
  xOffsetOverlap,
  yOffsetOverlap,
  child2Style,
  child1Style,
  layoutChangerStyles,
  reverse,
  marginBtwChilds = 7,
  overlapOnMobile = true,
  standardPaddingStyles,
  isMobileImage16By9AspectRatio = false,
  isCarouselWithControls = false,
}) => {
  mediaPositioning = mediaPositioning === null ? "floating" : mediaPositioning
  const layoutType =
    location === "bottom" || location === "top" ? "stacked" : "adjacent"
  xOffsetOverlap =
    layoutType === "stacked"
      ? (text?.titleMaxWidth === "fullWidth" ||
          text?.titleMaxWidth === "wide") &&
        xOffsetOverlap >= 200
        ? 200
        : xOffsetOverlap >= 383
        ? 383
        : xOffsetOverlap <= -400
        ? -400
        : xOffsetOverlap
      : xOffsetOverlap //limitting xOffesetOverlap based on layout settings

  const overlap = layoutStyle === "overlapping"
  mediaRatio = mediaRatio === null ? 50 : mediaRatio

  const isMediaPositioningFloating =
    mediaPositioning && mediaPositioning === "floating"

  const alignProp =
    overlap && layoutType === "stacked"
      ? "end"
      : overlap && layoutType === "adjacent" && mediaPositioning !== "floating"
      ? "center"
      : overlap && layoutType === "adjacent"
      ? "start"
      : !isMediaPositioningFloating
      ? "center"
      : align //setting align setting depending on layout when overlap is turned on

  section.verticalPadding =
    section.verticalPadding === null
      ? "default"
      : overlap && !isMediaPositioningFloating
      ? "md" //defaulting verticalPadding to overlap and non-floating layout
      : section.verticalPadding
  const hasImageOrVideo =
    (imageOrVideo?.isVideoSelected &&
      (imageOrVideo?.videoURL || imageOrVideo?.videoImageFallback)) ||
    imageOrVideo?.desktopImage ||
    imageOrVideo?.mobileImage

  const imageOrVideoStyles = !isMediaPositioningFloating
    ? {
        width: "100%",
        height: "100%",
      }
    : { ...imageOrVideo?.imageStyles }
  const defaultPadding =
    section.customVerticalPadding > 0 &&
    Math.floor(section.customVerticalPadding / 32) >= 2
      ? section.customVerticalPadding - 16
      : 32
  const customPadding = {
    _: section.verticalPadding === "custom" ? `${defaultPadding}px` : 7,
    lg: `${section.customVerticalPadding}px`,
  }

  let child1StyleProp = {
    alignSelf:
      mediaPositioning !== "floating" && !overlap
        ? "center"
        : alignProp === "center"
        ? "center"
        : `flex-${alignProp}`,
    width: layoutType === "adjacent" ? "100%" : "inherit",
    zIndex: overlap ? 15 : 10,
    flex: 1,
    minWidth:
      hasImageOrVideo && layoutType === "adjacent" && mediaRatio > 50
        ? { _: "100%", md: "0%" }
        : null,
  }

  let child2StyleProp = {
    zIndex: 12,
    alignSelf: !isMediaPositioningFloating
      ? { _: "center", sm: "stretch" }
      : { _: overlap ? "end" : "center", md: alignProp },
    width: "100%",
    flex: mediaRatio ? mediaRatio / (100 - mediaRatio) : 1,
  }

  let conditionalProps = {
    py: {
      _: 0, // prevent unwanted spacing between image and text on mobile pb
      md: !overlap
        ? !isMediaPositioningFloating && section.verticalPadding === "custom"
          ? customPadding.lg
          : !isMediaPositioningFloating && section.verticalPadding === "md"
          ? 9
          : (!isMediaPositioningFloating && section.verticalPadding === "lg") ||
            (!isMediaPositioningFloating &&
              section.verticalPadding === "fullHeight")
          ? 12
          : null
        : overlap
        ? !isMediaPositioningFloating
          ? 0
          : null
        : null,
    },
    verticalPadding: "custom",
    position: "relative",
    zIndex: 10,
    width: "100%",
    height: "100%",
  }
  let imageOrVideoBoxStyles = {}

  if (mediaPositioning === "floating") {
    conditionalProps.px = { _: 0 } //conditionalProps.px = { _: 5, sm: 5, md: 0 }
    if (location === "left") {
      child2StyleProp.ml = { _: 0, md: 7 }
      child2StyleProp.mr = { _: 0 }
    } else if (location === "right") {
      child2StyleProp.mr = { _: 0, md: 7 }
      child2StyleProp.ml = { _: 0 }
    }
  } else if (mediaPositioning === "fit") {
    conditionalProps.px = { _: 5, md: 7, xl: 8 } // this padding needs to be apply to the children content. not directly to child1/child2 to maintain 50% / 50% dimessions
  }
  conditionalProps.pl =
    mediaPositioning === "fitToHeight" && location === "left"
      ? { _: 5, lg: 7, xl: 4 }
      : mediaPositioning === "fitToHeight"
      ? { _: 5, lg: 7 }
      : null

  conditionalProps.pr =
    mediaPositioning === "fitToHeight" && location === "right"
      ? { _: 5, lg: 7, xl: 4 }
      : mediaPositioning === "fitToHeight"
      ? { _: 5, lg: 7 }
      : null

  let yOffsetOverlapPixels = Math.abs(yOffsetOverlap)
  yOffsetOverlapPixels =
    yOffsetOverlapPixels > 340 && layoutType === "stacked"
      ? 340
      : yOffsetOverlapPixels

  card = {
    ...card,
    useCard: overlap && !card?.useCard ? true : card?.useCard,
    theme: overlap && !card?.useCard ? "light" : card?.theme,
    bg: overlap && !card?.useCard ? 0 : card?.bg,
  }

  const shouldDisabledXOffset =
    text?.titleMaxWidth === "fullWidth" && layoutType === "stacked"
      ? true
      : false // disabling x-offset to prevent x-axis cut off

  // ------------------------------Mobile Padding--------------------------------------------------
  const stdPaddingForNonFloatingLayout = {
    _:
      section.verticalPadding === "lg"
        ? 11
        : section.verticalPadding === "default" ||
          section.verticalPadding === "md"
        ? 7
        : section.verticalPadding === "sm"
        ? 5
        : section.verticalPadding === "fullHeight"
        ? 7
        : customPadding._,
    // lg: 0,
  } // adds padding at mobile breakpoint since there is no standard padding when !isMediaPositioningFloating
  if (!isMediaPositioningFloating) {
    child2StyleProp.mt = {
      _: overlap && mediaPositioningOnMobile === "top" ? 7 : "0px", //setting marging top to zero pixel for media on mobile layout
      md: "0px",
    }
    if (mediaPositioningOnMobile === "bottom") {
      if (!overlap) {
        conditionalProps.pt = stdPaddingForNonFloatingLayout
        conditionalProps.pb = {
          _: !overlap ? marginBtwChilds : 0,
          md: stdPaddingForNonFloatingLayout._,
        } // limiting space btw image and tex to marginBtwChilds spacing
      }

      if (isCarouselWithControls) {
        imageOrVideoBoxStyles.pb = { ...stdPaddingForNonFloatingLayout, md: 0 }
      }
    }

    if (mediaPositioningOnMobile === "top") {
      conditionalProps.pb = {
        ...stdPaddingForNonFloatingLayout,
      }
    }
  }
  // --------------------------------------------------------------------------------

  if (mediaPositioning !== "floating") {
    child2StyleProp.ml = 0 // marginBtwChild is being handled by px or standard padding for this layout
    child2StyleProp.mr = 0 // marginBtwChild is being handled by px or standard padding for this layout
  }

  if (hasImageOrVideo && overlap) {
    //------------------------ child1 overlaping margin top logic  ------------------------
    if (mediaPositioning === "floating") {
      child2StyleProp.ml = 0 //setting x-offset to zero pixel btw image and text when x-offset is zero
      child2StyleProp.mr = 0 //setting x-offset to zero pixel btw image and text when x-offset is zero
      child1StyleProp.mt = {
        _: !overlapOnMobile
          ? marginBtwChilds
          : mediaPositioningOnMobile === "bottom"
          ? 0
          : yOffsetOverlap > 56
          ? 56
          : `${-yOffsetOverlapPixels * 0.25}px`,
        sm:
          mediaPositioningOnMobile === "bottom"
            ? 0
            : yOffsetOverlap > 56
            ? 56
            : `${yOffsetOverlap * 0.5}px`,
        md: `${
          layoutType === "adjacent" && yOffsetOverlap > 0
            ? yOffsetOverlapPixels //this margin needs to be positive to prevent card oveflowing to sectionTItle
            : location === "top" ||
              (layoutType === "adjacent" && yOffsetOverlap <= 0)
            ? 0
            : yOffsetOverlap < -56
            ? 56 //limitting margin between image and text to at most 56 pixels
            : yOffsetOverlap < 0
            ? yOffsetOverlapPixels
            : -yOffsetOverlapPixels
        }px`,
      }

      child2StyleProp.mt = {
        _: !overlapOnMobile
          ? marginBtwChilds
          : mediaPositioningOnMobile === "bottom"
          ? `${-yOffsetOverlapPixels * 0.15}px`
          : 0,
        md: "0px", //setting y-offset to zero pixel btw image and text when y-offset is zero
      }
    }

    if (!isMediaPositioningFloating) {
      child1StyleProp.mt = {
        _: !overlapOnMobile
          ? marginBtwChilds
          : mediaPositioningOnMobile === "bottom"
          ? 0
          : yOffsetOverlap > 56
          ? -56
          : `${-yOffsetOverlapPixels * 0.25}px`, // overlaping both element on mobile
        sm:
          mediaPositioningOnMobile === "top"
            ? `${-yOffsetOverlapPixels * 0.25}px`
            : 0, //fit and half fit layout turns into a stack layout on mobile
        md: yOffsetOverlap > 0 ? `${yOffsetOverlapPixels * 0.25}px` : 0, // margin needs to be position on desktop layout to prevent overflow
        lg: yOffsetOverlap > 0 ? `${yOffsetOverlapPixels}px` : 0, // margin needs to be position on desktop layout to prevent overflow
      }
    }
    //------------------------ x-offset overlaping logic  ------------------------
    if (!shouldDisabledXOffset) {
      conditionalProps.left =
        location === "left" && !isMediaPositioningFloating
          ? {
              _: "0px",
              sm: !overlapOnMobile
                ? "0px"
                : mediaLocation === "left"
                ? `24px`
                : `-24px`,
              md: `${xOffsetOverlap}px`,
            }
          : location === "left" && mediaPositioning === "floating"
          ? {
              _: "0px",
              sm: !overlapOnMobile
                ? "0px"
                : mediaLocation === "left"
                ? `24px`
                : `-24px`,
              md: `${xOffsetOverlap}px`,
            }
          : location === "right" && !isMediaPositioningFloating
          ? {
              _: "0px",
              sm: !overlapOnMobile
                ? "0px"
                : mediaLocation === "left"
                ? `24px`
                : `-24px`,
              md: `-${Math.abs(xOffsetOverlap)}px`,
            }
          : (location === "right" && mediaPositioning === "floating") ||
            layoutType === "stacked"
          ? {
              _: "0px",
              sm: !overlapOnMobile
                ? "0px"
                : mediaLocation === "left"
                ? `24px`
                : `-24px`,
              sm: `-${Math.abs(xOffsetOverlap * 0.1)}px`,
              md: `-${Math.abs(xOffsetOverlap * 0.2)}px`,
              lg: `-${Math.abs(xOffsetOverlap * 0.45)}px`,
              xl: `-${Math.abs(xOffsetOverlap)}px`,
            } // using pixel amt as default values here since spacing constant values from SS did not work
          : null
    }
    //--------------------------------------------------------------------------------------------
    if (mediaPositioning === "floating") {
      child2StyleProp.mr = {
        _: !overlapOnMobile ? 0 : mediaLocation === "right" ? -5 : 5,
        md: 0,
      }

      if (layoutType === "adjacent" && yOffsetOverlap < 0) {
        child2StyleProp.mt = {
          _:
            mediaPositioningOnMobile === "bottom"
              ? `${-yOffsetOverlapPixels * 0.15}px`
              : 0,
          md: `${yOffsetOverlapPixels}px`,
        }
      }

      if (layoutType === "stacked") {
        child1StyleProp.mb = {
          _:
            mediaPositioningOnMobile === "top"
              ? 0
              : yOffsetOverlap > 56
              ? 56
              : `${yOffsetOverlap * 0.25}px`,
          sm:
            yOffsetOverlap > 56
              ? `${56}px`
              : location === "bottom" && mediaPositioningOnMobile === "bottom"
              ? `${-yOffsetOverlapPixels * 0.15}px`
              : mediaPositioningOnMobile === "top"
              ? 0
              : `${yOffsetOverlap * 0.7}px`,
          md:
            yOffsetOverlap > 56
              ? `${56}px`
              : location === "top"
              ? `${
                  yOffsetOverlap < 0
                    ? -yOffsetOverlapPixels
                    : yOffsetOverlapPixels
                }px`
              : null,
        }
      }
    }

    if (!isMediaPositioningFloating) {
      child1StyleProp.mb = {
        _:
          mediaPositioningOnMobile === "top"
            ? 0
            : `${-yOffsetOverlapPixels * 0.15}px`,
        sm:
          mediaPositioningOnMobile === "top"
            ? 0
            : `${-yOffsetOverlapPixels * 0.25}px`,
        md: yOffsetOverlap < 0 ? `${yOffsetOverlapPixels}px` : 0,
      }
    }
  }

  if (hasImageOrVideo && overlap && mediaPositioning !== "floating") {
    //these padding should be combined with the conditionalProps variable.
    //and the hardcoded 128 should be replace with py value

    child1StyleProp.pb = {
      _: mediaPositioningOnMobile === "bottom" ? marginBtwChilds : 0,
      md: yOffsetOverlapPixels < 128 ? `${128 - yOffsetOverlapPixels}px` : 0,
    }

    child1StyleProp.pt = {
      _: marginBtwChilds,
      md:
        yOffsetOverlap < 0 && yOffsetOverlapPixels < 128
          ? yOffsetOverlapPixels - 128 > 0
            ? `${yOffsetOverlapPixels - 128}px`
            : 0
          : 0,
    }
  }

  const textBoxStyles = {}

  if (hasImageOrVideo && layoutType === "adjacent") {
    if (!isMediaPositioningFloating) {
      if (overlap) {
        if (text?.titleMaxWidth === "normal") {
          textBoxStyles.width = { _: "100%", md: `${50 + mediaRatio}%` }
        } else if (text?.titleMaxWidth === "wide") {
          textBoxStyles.width = { _: "100%", md: `${80 + mediaRatio}%` }
        } else if (text?.titleMaxWidth === "fullWidth") {
          textBoxStyles.width = { _: "100%", md: `${110 + mediaRatio}%` }
        }
      } else if (mediaRatio === null) {
        if (text?.titleMaxWidth === "normal") {
          textBoxStyles.width = { _: "100%", md: "100%" }
        } else if (text?.titleMaxWidth === "wide") {
          textBoxStyles.width = { _: "100%", md: 46 }
        } else if (text?.titleMaxWidth === "fullWidth") {
          textBoxStyles.width = { _: "100%", md: 56 }
        }
      }
    } else {
      textBoxStyles.width = { _: "100%", md: "100%" }
    }
  }

  child1StyleProp = { ...child1StyleProp, ...child1Style }
  child2StyleProp = { ...child2StyleProp, ...child2Style }

  standardPaddingStyles = { ...conditionalProps, ...standardPaddingStyles }

  return (
    <LayoutChanger
      itemsMoveStackedOnColumn={overlap ? false : true}
      disableMaxWidth={layoutType === "stacked" && mediaWidth !== null}
      child1Style={{
        ...child1Style,
        ...child1StyleProp,
      }}
      child2Style={{
        ...child2Style,
        ...child2StyleProp,
      }}
      location={location}
      child1Px={child1Px}
      align={alignProp}
      marginBtwChilds={
        overlap && !isMediaPositioningFloating && card.useCard
          ? 10
          : !isMediaPositioningFloating && overlap
          ? 0
          : isNaN(marginBtwChilds)
          ? 7
          : marginBtwChilds
      }
      reverse={reverse ? reverse : true}
      child1FlexDirOnMobile={
        mediaPositioningOnMobile === "top" ? "column-reverse" : "column"
      }
      {...layoutChangerStyles}
    >
      <Reveal height="100%" width="100%">
        <StandardPadding {...standardPaddingStyles}>
          {textChild && (
            <Box className="textBoxStyles" {...textBoxStyles}>
              {textChild}
            </Box>
          )}
        </StandardPadding>
      </Reveal>

      {hasImageOrVideo && (
        <Box
          position="relative"
          width="100%"
          display="flex"
          justifyContent={
            (!isMediaPositioningFloating && location === "right") ||
            (overlap && layoutType === "stacked" && mediaLocation === "right")
              ? "flex-end"
              : (!isMediaPositioningFloating && location === "left") ||
                (overlap && layoutType === "stacked")
              ? "flex-start"
              : "center"
          }
          height={
            mediaPositioning === "fit" || mediaPositioning === "fitToHeight"
              ? "100%"
              : "auto"
          }
          {...imageOrVideoBoxStyles}
        >
          <Box
            position="relative"
            height="100%"
            width="100%"
            maxWidth={
              layoutType === "adjacent"
                ? "100%"
                : mediaWidth !== null
                ? `${mediaWidth}%`
                : 44
            }
          >
            <Reveal height="100%" width="100%">
              <StyledImageOrVideo
                {...imageOrVideo}
                videoProps={{
                  ...imageOrVideo.videoProps,
                  ...imageOrVideoStyles,
                }}
                imageStyles={imageOrVideoStyles}
                mediaPositioningNotFloating={
                  !isMediaPositioningFloating && overlap
                }
                isMobileImage16By9AspectRatio={isMobileImage16By9AspectRatio}
              />
            </Reveal>
          </Box>
        </Box>
      )}
    </LayoutChanger>
  )
}

export default ImageTextMolecule
// export const query = graphql`
//   fragment ImageTextMolecule on STRAPI__COMPONENT_MOLECULES_IMAGE_TEXT_MOLECULE {
//     id
//     align
//     card
//     child1Px
//     imageOrVideo
//     imageTextAlign
//     imageTextLocation
//     layoutStyle
//     location
//     mediaLocation
//     mediaPositioning
//     mediaPositioningOnMobile
//     mediaRatio
//     section
//     socialLinkStyles
//     text
//     xOffsetOverlap
//     yOffsetOverlap
//     textChild
//   }
// `
