import React, { useMemo } from "react"

import Box from "../../atoms/Box"
import Button from "../../atoms/Button"
import Card from "../../atoms/Card"
import Flex from "../../atoms/Flex"

import ImageTextCarouselItem from "../../molecules/ImageTextCarouselItem"
import { getMarginBtwChilds } from "../../molecules/ImageText/ImageTextPopup/utils"
import Newsletter from "../..//molecules/Newsletter"

import SocialLink from "../../utils/SocialLink"

import { notGatsby } from "gatsby"

// destructured variables in the function parameters below should come from props passed as an argument
//
export const getMediaPositioning = ({
  imageTextCarouselMediaPositioning,
  mediaPositioning,
  showMedia,
}) => {
  const mediaPositioningProp = !notGatsby
    ? imageTextCarouselMediaPositioning
    : mediaPositioning
  const value =
    mediaPositioningProp === null || !showMedia
      ? "floating"
      : mediaPositioningProp ///imageTextCarouselMediaPositioning || mediaPositioning

  return value
}

export const getSectionVerticalPadding = ({ section }) => {
  if (!section) return null

  return section?.verticalPadding === null
    ? "default"
    : section?.verticalPadding === "fullHeight"
    ? "custom"
    : section?.verticalPadding
}

export const isMediaPositioningFloating = ({
  mediaPositioning,
  imageTextCarouselMediaPositioning,
}) => {
  let result = false

  if (mediaPositioning)
    result =
      mediaPositioning === "floating" ||
      imageTextCarouselMediaPositioning === "floating"

  return result
}

export const getMediaStyles = isMediaPositioningFloating => {
  return isMediaPositioningFloating
    ? {}
    : {
        width: "100%",
        height: "100%",
      }
}
export const getItems = props => {
  return props.items?.map(({ imageOrVideo, text, ...rest }) => {
    imageOrVideo = {
      ...imageOrVideo,
      controls: props.imageOrVideoStyle?.controls,
      playing: props.imageOrVideoStyle?.playing,
      loop: props.imageOrVideoStyle?.loop,
      volume: props.imageOrVideoStyle?.volume,
      imageStyles: {
        objectFit: "contain",
      },
    }

    const textChild = renderTextChild(
      {
        imageOrVideo,
        text,
        card: props.card,
        textAlign: props.textAlign,
        ...rest,
        ...props,
      },

      getAlign(
        props,
        isOverlapping(props),
        getLayoutType(getLocation(props)),
        getMediaPositioning(props)
      ),
      hasImageOrVideo(props),
      getMediaRatio(props),
      getLayoutType(getLocation(props)),
      getMediaPositioning(props),
      isOverlapping(props)
    )

    return { textChild, imageOrVideo, text, ...rest }
  })
}

export const getDefaultPadding = ({ section }) => {
  if (!section || !section.verticalPadding || isNaN(section?.verticalPadding))
    return null

  return section?.customVerticalPadding > 0 &&
    Math.floor(section?.customVerticalPadding / 32) >= 2
    ? section?.customVerticalPadding - 16
    : 32
}

export const getCustomPadding = ({ section }, defaultPadding) => {
  return {
    _: section?.verticalPadding === "custom" ? `${defaultPadding}px` : 7,
    lg: section?.customVerticalPadding
      ? `${section?.customVerticalPadding}px`
      : null,
  }
}

export const getConditionalProps = (
  { section },
  isMediaPositioningFloating,
  customPadding,
  overlap
) => {
  if (
    !section ||
    !section.verticalPadding ||
    isNaN(section?.verticalPadding) ||
    !customPadding
  )
    return null

  return {
    py: {
      _: !isMediaPositioningFloating && !overlap ? 7 : 0,
      // prevent unwanted spacing between image and text on mobile pb
      lg: !overlap
        ? !isMediaPositioningFloating && section.verticalPadding === "custom"
          ? customPadding.lg
          : !isMediaPositioningFloating && section.verticalPadding === "md"
          ? 9
          : (!isMediaPositioningFloating && section.verticalPadding === "lg") ||
            (!isMediaPositioningFloating &&
              section.verticalPadding === "fullHeight")
          ? 12
          : null
        : overlap
        ? !isMediaPositioningFloating
          ? 0
          : null
        : null,
    },
  }
}

export const isOverlapping = ({
  imageTextCarouselLayoutStyle,
  layoutStyle,
}) => {
  return Boolean(
    layoutStyle === "overlapping" ||
      imageTextCarouselLayoutStyle === "overlapping"
  )
}
export const getLayoutStyle = ({
  imageTextCarouselLayoutStyle,
  layoutStyle,
}) => {
  return imageTextCarouselLayoutStyle || layoutStyle
}
export const getMediaPositioningOnMobile = ({
  imageTextCarouselMediaPositioningOnMobile,
  mediaPositioningOnMobile,
}) => {
  return imageTextCarouselMediaPositioningOnMobile || mediaPositioningOnMobile
}

export const renderTextChild = (
  {
    text,
    card,
    textAlign,
    imageTextCarouselTextAlign,
    button,
    button2,
    imageOrVideo,
    backgroundImage,
    backgroundImageMobile,
    icon,
    ...rest
  },
  align,
  hasImageOrVideo,
  mediaRatio,
  layoutType,
  mediaPositioning,
  isMediaPositioningFloating,
  isOverlap
) => {
  const textAlignProp = textAlign || imageTextCarouselTextAlign
  const MAX_WIDTH_TEXT = 44
  if (!text) return null

  return (
    <Card
      className="card"
      pt={{ _: card?.useCard ? 5 : 2, md: card?.useCard ? 6 : 5 }}
      pb={{ _: card?.useCard ? 5 : 2, md: card?.useCard ? 6 : 5 }}
      px={{ _: card?.useCard ? 5 : 0, md: card?.useCard ? 6 : 0 }}
      width={
        hasImageOrVideo && isOverlap && layoutType === "adjacent"
          ? isOverlap && mediaPositioning === "floating"
            ? "100%"
            : !isMediaPositioningFloating
            ? { _: "100%", md: `${135 + mediaRatio}%` }
            : { _: "100%", md: `${210 + mediaRatio}%` }
          : null
      }
      {...card}
    >
      <Flex flexDirection={"column"} height={"100%"}>
        <Flex height={"100%"}>
          <ImageTextCarouselItem
            maxWidth={MAX_WIDTH_TEXT}
            textAlign={textAlignProp}
            alignSelf={align === "center" ? "center" : align}
            text={text}
            button={button}
            button2={button2}
            imageOrVideo={imageOrVideo}
            backgroundImage={backgroundImage}
            backgroundImageMobile={backgroundImageMobile}
            icon={icon}
            {...rest}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
export const getImageTextMoleculeProps = (
  {
    section,
    child1Px,
    mediaLocation,
    mediaRatio,
    xOffsetOverlap,
    yOffsetOverlap,
    card,
    openModalSectionById,
    conditionalProps,
    isMediaPositioningFloating,
    customPadding,
  },
  mediaPositioning,
  align,
  layoutStyle,
  location,
  controlsLocation,
  mediaPositioningOnMobile,
  overlap,
  marginBtwChildsDefault
) => {
  // const layoutChangerStyles = {}

  const child1Style = {}
  const child2Style = {}
  if (mediaPositioning !== "floating") {
    child1Style.mb = { _: 0, lg: "85px" } // keeping margin btw controls and text box
    child2Style.mb = { _: 0 } // keeping margin btw controls and text box
    child2Style.maxHeight = { _: "300px", md: "400px" }
  }

  return {
    child1Px,
    mediaLocation,
    mediaPositioningOnMobile,
    mediaRatio,
    xOffsetOverlap,
    yOffsetOverlap,
    card,
    // layoutChangerStyles,
    child1Style,
    openModalSectionById,
    conditionalProps,
    isMediaPositioningFloating,
    overlap,
    customPadding,
    mediaPositioning,
    align,
    layoutStyle,
    location,
    controlsLocation,
    child2Style,
    // marginBtwChilds,
  }
}

export const hasImageOrVideo = props => {
  return (
    (props.imageOrVideo?.isVideoSelected &&
      (props.imageOrVideo?.videoURL ||
        props.imageOrVideo?.videoImageFallback)) ||
    props.imageOrVideo?.desktopImage ||
    props.imageOrVideo?.mobileImage
  )
}

export const getControlsLocation = (
  { card, controlsLocation, imageTextCarouselControlsLocation, showMedia },
  isOverlapping,
  mediaPositioning,
  layoutType
) => {
  return !showMedia
    ? "text"
    : layoutType === "stacked"
    ? "section"
    : (isOverlapping || card?.useCard) && mediaPositioning !== "floating"
    ? "section"
    : controlsLocation || imageTextCarouselControlsLocation
}
export const getMediaRatio = ({ mediaRatio }) => {
  return mediaRatio === null ? 50 : mediaRatio
}

export const getLocation = ({
  imageTextCarouselTextLocation,
  location,
  showMedia,
}) => {
  return !showMedia ? "top" : imageTextCarouselTextLocation || location
}
// export const getAlign = ({ imageTextCarouselAlign, align }) => {
//   return imageTextCarouselAlign || align
// }

export const getAlign = (
  { align, imageTextCarouselAlign },
  overlap,
  layoutType,
  mediaPositioning
) => {
  const result =
    overlap && layoutType === "stacked"
      ? "end"
      : overlap && layoutType === "adjacent" && mediaPositioning !== "floating"
      ? "center"
      : overlap && layoutType === "adjacent"
      ? "start"
      : imageTextCarouselAlign || align //setting align setting depending on layout when overlap is turned on

  return result
}

export const getLayoutType = location => {
  const locationProp = location
  if (!locationProp || locationProp === "right" || locationProp === "left")
    return "adjacent"

  if (locationProp === "bottom" || locationProp === "top") return "stacked"
}

export const renderNewsletter = ({ useNewsletter, newsletter }) => {
  if (!useNewsletter) return null

  return (
    <Box mt={5}>
      <Newsletter justify={"start"} {...newsletter} />
    </Box>
  )
}

export const renderButtons = ({
  button,
  button2,
  openModalSectionById,
  modal,
  text,
}) => {
  if (!button && !button2) return null

  if (button && button2 && modal?.size?.sizeSelections === "sm") {
    return (
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-end"
        style={{ width: "70%" }}
      >
        <Button
          aria-label="CTA button 1 in a text carousel"
          mt={5}
          openModalSectionById={openModalSectionById}
          {...button}
        />
        <Button
          aria-label="CTA button 2 in a text carousel"
          mt={5}
          {...button2}
        />
      </Flex>
    )
  }

  const buttons = []
  if (button) {
    buttons.push(
      <Button
        aria-label="CTA button 1 in a text carousel"
        mt={5}
        mr={{ _: 0, md: button2 ? 5 : 0 }}
        openModalSectionById={openModalSectionById}
        {...button}
      />
    )
  }

  if (button2) {
    buttons.push(
      <Button
        aria-label="CTA button 2 in a text carousel"
        mt={5}
        {...button2}
        openModalSectionById={openModalSectionById}
      />
    )
  }

  return (
    <Flex
      flexDirection={{ _: "column", md: "row" }}
      justifyContent={text?.align === "center" ? "center" : "flex-start"}
      alignItems={text?.align === "center" ? "center" : "flex-start"} //needed for mobile alignment
    >
      {buttons}
    </Flex>
  )
}

export const renderSocials = ({ socialLinks, socialLinkStyles, text }) => {
  if (socialLinks?.length === 0 || socialLinks === undefined) return null

  const socialStyles =
    socialLinkStyles === undefined || socialLinkStyles === null
      ? {
          color: "primary",
          theme: "highlight",
          bg: 3,
          iconStyle: "solid",
        }
      : socialLinkStyles

  return (
    <Box className="social" mt={5} mb={4} textAlign={text?.align}>
      {socialLinks?.map(socialLink => {
        return (
          <SocialLink {...socialLink} {...socialStyles} mr={5} mt={2} mb={3} />
        )
      })}
    </Box>
  )
}

// left commented out code to highlight which functions need normalizedProps once we use the image text molecule again
export const useMemoizedProps = props => {
  const storage = {
    isMediaPositioningFloating: isMediaPositioningFloating(props),

    // isMediaPositioningFloating: useMemo(
    //   () => isMediaPositioningFloating(normalizedProps),
    //   [props.imageTextMolecule.mediaPositioning]
    // ),
    items: getItems(props),
    controlsLocation: getControlsLocation(
      props,
      isOverlapping(props),
      getMediaPositioning(props),
      getLayoutType(getLocation(props))
    ),
    mediaPositioning: getMediaPositioning(props),
    // mediaPositioning: useMemo(() => getMediaPositioning(normalizedProps), [
    //   props.imageTextMolecule.mediaPositioning,
    // ]),
    conditionalProps: getConditionalProps(
      props,
      isMediaPositioningFloating(props),
      getCustomPadding(props, getDefaultPadding(props)),
      isOverlapping(props)
    ),
    verticalPadding: useMemo(() => getSectionVerticalPadding(props), [
      props.section,
    ]),
    overlap: isOverlapping(props),
    customPadding: getCustomPadding(props, getDefaultPadding(props)),
    imageTextMoleculeProps: getImageTextMoleculeProps(
      props,
      getMediaPositioning(props),
      getAlign(
        props,
        isOverlapping(props),
        getLayoutType(getLocation(props)),
        getMediaPositioning(props)
      ),
      getLayoutStyle(props),
      getLocation(props),
      getControlsLocation(props, isOverlapping(props)),
      getMediaPositioningOnMobile(props),
      isOverlapping(props),
      getMarginBtwChilds(props, {
        isMediaPositioningFloating: isMediaPositioningFloating(props),
      })
    ),
  }

  return Object.keys(storage).reduce((result, field) => {
    result[field] = storage[field]
    return result
  }, {})
}
// textChild: useMemo(
//   () =>
//     renderTextChild(
//       props,
//       hasImageOrVideo(props),
//       getMediaRatio(props),
//       getLayoutType(getLocation(props)),
//       getMediaPositioning(props),
//       getAlign(props),
//       isMediaPositioningFloating(props)
//     ),
//   [
//     props.text,
//     props.card,
//     props.isMediaPositioningFloating,
//     props.overlap,
//     props.mediaPositioning,
//     props.imageOrVideo,
//     props.imageTextCarouselTextLocation,
//     props.location,
//     props.button,
//     props.button2,
//     props.openModalSectionById,
//     props.mediaPositioning,

//     // props.child1Px,
//     // props.mediaRatio,
//     // { text, card, isMediaPositioningFloating, overlap },

//     // imageTextCarouselControlsLocation: controlsLocation
//     // imageTextCarouselControlsAlignment: controlsAlignment
//     // imageTextCarouselShowMedia: showMedia
//     // imageTextCarouselShowArrows: showArrows
//     // imageTextCarouselShowIndicators: showIndicators
//     // imageTextCarouselTextAlign: textAlign
//     // imageTextCarouselRotateSpeed: rotateSpeed
//     // imageTextCarouselAlign: align
//     // imageTextCarouselTextLocation: location
//     // imageTextCarouselLayoutStyle: layoutStyle
//     // imageTextCarouselMediaLocation: mediaLocation
//     // imageTextCarouselMediaPositioning: mediaPositioning
//     // imageTextCarouselMediaPositioningOnMobile: mediaPositioningOnMobile
//   ]
// ),
