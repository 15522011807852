import React, { useState, useEffect } from "react"

import { compose, color, space } from "styled-system"
import styled from "styled-components"
import css from "../../lib/styled-system/css"

import useBreakpoint from "../../hooks/use-breakpoint"
import SliderControlArrow from "../icons/SliderControlArrow"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import ImageOrVideo from "../atoms/ImageOrVideo"
import Indicator from "../atoms/Indicator"
import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"

import ImageTextMolecule from "./ImageText/ImageTextMolecule"
import SectionTitle from "../molecules/SectionTitle"

const StyledButton = styled("button")`
  ${compose(space, color)}
  svg {
    ${props => props.$flip && "transform: scaleX(-1);"}
  }
`
// this seems to act more like a delay on the animation
const TRANSITION_LENGTH_MS = 50

export const Animation = styled(Box)`
  ${css({
    transitionDuration: "md",
    transitionTimingFunction: "ease",
  })}

  opacity: ${({ state }) =>
    state === "entering" || state === "entered" ? 0 : 1};
`

export const OpacityTransition = styled(Box)`
  ${css({
    transitionDuration: "md",
    transitionTimingFunction: "ease",
  })}
`

const MAX_WIDTH_TEXT = 44

const GenericImageCarousel = ({
  items,
  sectionTitle,
  section,
  mediaLocation,
  showMedia,
  controlsLocation,
  controlsAlignment,
  textAlign,
  controls,
  indicators,
  rotateSpeed,
  card,
  imageTextMolecule,
}) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const [transition, setTransition] = useState(false)
  const [isVideoPlaying, updateVideoState] = useState(false)

  const multipleItems = items?.length > 1
  const isMobileBp = useBreakpoint("md")
  const centerControls = controlsAlignment === "center"
  const controlsOnSection = controlsLocation === "section" || isMobileBp //defaulting controls to section on mobile

  const constrolsOnSectionStyles = {
    width: "100%",
    position: { _: "relative" },
  }
  if (imageTextMolecule.mediaPositioning === "floating") {
    constrolsOnSectionStyles.mt = { _: "40px", sm: "70px", md: "50px" }
  }

  if (imageTextMolecule.mediaPositioning !== "floating") {
    constrolsOnSectionStyles.mb = { _: "32px", md: "0px" }
    if (imageTextMolecule.layoutStyle === "standard") {
      constrolsOnSectionStyles.mt = { _: "0px", md: "50px" }
    }
    if (imageTextMolecule.layoutStyle === "overlapping") {
      constrolsOnSectionStyles.mt = { _: "70px", md: "50px" }
    }
    if (imageTextMolecule.location === "right") {
      constrolsOnSectionStyles.right = { _: 0, md: -5 }
    }
    if (imageTextMolecule.location === "left") {
      constrolsOnSectionStyles.left = 0
    }
    constrolsOnSectionStyles.position = { _: "relative", md: "absolute" }

    if (imageTextMolecule.location === "left" && controlsAlignment === "left") {
      constrolsOnSectionStyles.pl = { _: 0, md: 6, lg: 7 }
    }
    if (
      imageTextMolecule.location === "right" &&
      controlsAlignment === "right"
    ) {
      constrolsOnSectionStyles.pr = { _: 0, md: 6, lg: 7 }
    }

    if (showMedia) {
      constrolsOnSectionStyles.width = {
        _: "100%",
        md: `${47 + 0.75 * (50 - imageTextMolecule.mediaRatio)}%`, //keeping controls
      }
    }

    constrolsOnSectionStyles.bottom = { _: 0, md: 6 }
  }

  if (card === null || card === undefined) {
    card = { useCard: false }
  }

  useEffect(() => {
    if (multipleItems && rotateSpeed && !isVideoPlaying) {
      let interval
      if (transition) {
        interval = setInterval(() => {
          setTransition(false)
        }, TRANSITION_LENGTH_MS)
        return () => {
          clearInterval(interval)
        }
      }
      return () => {
        clearInterval(interval)
      }
    }
  }, [transition, items, rotateSpeed, isVideoPlaying])

  useEffect(() => {
    if (multipleItems && rotateSpeed && !isVideoPlaying) {
      let interval
      if (!transition || activeIdx >= items.length) {
        interval = setInterval(() => {
          setActiveIdx((activeIdx + 1) % items.length)
          setTransition(true)
        }, rotateSpeed * 1000)
        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [transition, items, rotateSpeed, isVideoPlaying])

  const setActiveIndexHelper = ({ idx }) => {
    setTransition(true)
    setActiveIdx(idx)
    updateVideoState(false) //pausing video player when carousel controls are clicked
  }

  const Controls = ({ videoURL }) => {
    if (!multipleItems) return null
    return (
      <Flex
        ml={
          controlsAlignment === "left" && showMedia && mediaLocation === "left"
            ? "auto"
            : 0
        }
        position="relative"
        zIndex={1}
        width={"100%"}
        maxWidth={
          !controlsOnSection &&
          controlsAlignment === "right" &&
          !showMedia &&
          textAlign !== "center"
            ? MAX_WIDTH_TEXT
            : "none"
        }
        mt={controlsOnSection ? 3 : 6}
        justifyContent={
          centerControls
            ? "center"
            : controlsAlignment === "left"
            ? "flex-start"
            : "flex-end"
        }
      >
        <Flex
          position="relative"
          left="0px"
          bottom="0px"
          justifyContent={centerControls ? "center" : "flex-start"}
          alignItems="center"
          height={3}
          m={0}
        >
          {controls && (
            <StyledButton
              $flip={true}
              mr={4}
              onClick={() => {
                setActiveIndexHelper({
                  idx: (activeIdx - 1) % items.length,
                  videoURL,
                })
              }}
              opacity={activeIdx === 0 ? 0.35 : 1}
              disabled={activeIdx === 0}
              aria-label="Previous slide"
            >
              <SliderControlArrow direction="right" />
            </StyledButton>
          )}

          {indicators &&
            [...Array(items?.length)].map((_item, idx) => (
              <Indicator
                onClick={() => setActiveIndexHelper({ idx, videoURL })}
                active={activeIdx === idx}
                ml={idx ? 3 : 0}
                idx={idx}
                key={idx}
                aria-label={idx}
              />
            ))}
          {controls && (
            <StyledButton
              ml={4}
              onClick={() =>
                setActiveIndexHelper({
                  idx: (activeIdx + 1) % items.length,
                  videoURL,
                })
              }
              opacity={activeIdx === items.length - 1 ? 0.35 : 1}
              disabled={activeIdx === items.length - 1}
              aria-label="Next slide"
            >
              <SliderControlArrow direction="right" />
            </StyledButton>
          )}
        </Flex>
      </Flex>
    )
  }

  const isMediaPositioningFloating =
    imageTextMolecule?.mediaPositioning === "floating"

  let carouselContent = (
    <>
      {sectionTitle && (
        <SectionTitle
          position="relative"
          zIndex={1}
          {...sectionTitle}
          maxWidth={42}
          mb={7}
        />
      )}
      <Card {...card}>
        <Box
          overflow="hidden"
          width="100%"
          py={card?.useCard === true ? 4 : null}
          px={card?.useCard === true ? 4 : null}
        >
          <Flex width="100%" py={null} className="container-carousel">
            {items?.map(({ imageOrVideo, textChild, text }, idx) => {
              const active = idx === activeIdx
              const carouselTextChild = (
                <Flex flexDirection="column" height="100%">
                  {textChild}
                  {!controlsOnSection &&
                    multipleItems &&
                    (controls || indicators) && (
                      <Controls videoURL={imageOrVideo.videoURL} />
                    )}
                </Flex>
              )

              return (
                <OpacityTransition
                  display="flex"
                  style={{
                    transform: `translateX(-${100 * idx}%)`,
                  }}
                  justifyContent="space-between"
                  zIndex={active ? 1 : -1}
                  opacity={active ? 1 : 0}
                  key={idx}
                  width="100%"
                  flex="1 0 100%"
                  alignItems={card?.useCard === true && "center"}
                  flexDirection="column"
                  // px={{ _: 5, lg: 7, xl: 4 }}
                >
                  <ImageTextMolecule
                    imageOrVideo={
                      showMedia
                        ? {
                            ...imageOrVideo,
                            playing: isVideoPlaying,
                            videoProps: {
                              onPlay: () => updateVideoState(true),
                              onPause: () => updateVideoState(false),
                            },
                          }
                        : null
                    }
                    reverse={showMedia && mediaLocation === "left"}
                    section={section}
                    textChild={carouselTextChild}
                    text={text}
                    isMobileImage16By9AspectRatio={true} // this ensure all images are the same height on mobile breakpoint
                    isCarouselWithControls={controls || indicators}
                    {...imageTextMolecule}
                  />
                </OpacityTransition>
              )
            })}
          </Flex>
          {controlsOnSection && multipleItems && (controls || indicators) && (
            <Box className="control-container" {...constrolsOnSectionStyles}>
              <Controls />
            </Box>
          )}
        </Box>
      </Card>
    </>
  )

  if (imageTextMolecule.mediaPositioning === "floating") {
    carouselContent = (
      <StandardPadding
        className="carousel-standard-padding"
        maxWidth={section.maxWidth}
        verticalPadding={section.verticalPadding}
        py={section.customVerticalPadding}
        // px={{ _: 5, md: 7, xl: 8 }}
        px={{ _: 5, md: 7, xl: 8 }}
      >
        {carouselContent}
      </StandardPadding>
    )
  }

  return (
    <Section
      className="ImageTextCarousel"
      {...section}
      noStandardPadding={true}
      customVerticalPadding={-1}
      maxWidth={"100%"}
      margin={section?.margin ? section?.margin : "0px auto"} // setting transparent nav margin when feature is turned on
    >
      {items?.map(({ backgroundImage, backgroundImageMobile }, idx) => {
        let media = (
          <ImageOrVideo
            alternativeText="Image of Carousel Slide"
            desktopImage={backgroundImage}
            mobileImage={backgroundImageMobile}
            isVideoSelected={false}
            width="100%"
          />
        )

        if (backgroundImage) {
          return (
            <OpacityTransition key={idx} opacity={activeIdx === idx ? 1 : 0}>
              <Box
                style={{ objectFit: "cover" }}
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                overflow="hidden"
              >
                {media}
              </Box>
              <Box
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                bg={`background.${section.bg}`}
                opacity={section.overlayOpacity || 0}
              />
            </OpacityTransition>
          )
        }
        return null
      })}

      {carouselContent}
    </Section>
  )
}

export default React.memo(GenericImageCarousel)
